import { useObjectGroupApi } from '@shared/api'
import { useTitle } from '@shared/hooks'

import { type AttributeGroupNameProps } from './types'

const AttributeGroupName: ReactFc<AttributeGroupNameProps> = ({ groupId }) => {
  const attributeGroupQuery = useObjectGroupApi(groupId.toString())
  const groupName = useTitle(attributeGroupQuery)

  return <>{groupName}</>
}

export { AttributeGroupName }
