import { ROUTES } from '@shared/config'
import { useParams } from 'react-router-dom'

export const usePrintLink = () => {
  const { id: skuId } = useParams()

  return () => {
    const printWindow = window.open(
      ROUTES.CATALOG.SKU.PRINT.buildPath({ id: Number(skuId) }),
      '_blank',
    )

    if (!printWindow) {
      console.error('Не удалось открыть новое окно для печати.')

      return
    }

    printWindow.open()

    const checkIframe = setInterval(() => {
      const iframe = printWindow.document.querySelector('iframe')

      if (iframe) {
        const iframeContent = iframe.contentWindow

        if (iframeContent && iframeContent.document.body.children.length > 0) {
          clearInterval(checkIframe)
          iframeContent.print()
        }
      }
    }, 500)
  }
}
